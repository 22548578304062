import { render, staticRenderFns } from "./GoodsType.vue?vue&type=template&id=f3c36014&scoped=true&"
import script from "./GoodsType.vue?vue&type=script&lang=js&"
export * from "./GoodsType.vue?vue&type=script&lang=js&"
import style0 from "./GoodsType.vue?vue&type=style&index=0&id=f3c36014&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3c36014",
  null
  
)

export default component.exports